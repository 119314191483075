<template>
  <b-card title="Aggregated Statistics">
    <!-- <div class="selected_filter">
      {{ selectedFilterName }}
      <b v-if="selectedFilterName === 'This Quarter'">{{ quarter }}</b>
    </div>
    <div class="b_header_earning">
      <div class="d-flex align-items-center justify-content-between">
        <div class="filters mr-2">
          <span
            v-for="filter in filters"
            :key="filter"
            :class="{ active: selectedFilterName === filter }"
            class="filter_text__"
            @click="selectFilter(filter)"
          >
            {{ filter }}
          </span>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <date-range-picker
          @update="handleDateUpdate"
          :locale-data="locale"
          :opens="opens"
          :ranges="ranges"
          :autoApply="autoApply"
          v-model="dateRange"
          class="date-range-picker"
        >
        </date-range-picker>
      </div>
    </div> -->
    <!-- <b-card-text class="mr-25 mb-0">Updated 1 month ago</b-card-text> -->
    <b-card-body class="">
      <b-row class="">
        <b-col
          v-for="(value, key) in statistics"
          :key="key"
          md="3"
          sm="4"
          class="pl-3 align-items-center mb-5"
        >
          <b-media no-body>
            <b-media-aside class="">
              <b-avatar size="52" :variant="value.color">
                <feather-icon size="24" :icon="value.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4
                class="font-weight-bolder font-large-1 mb-0 cursor-pointer"
                @click="redirectTo(value.subtitle)"
              >
                {{ value.value }}
              </h4>
              <b-card-text class="font-medium-3 mb-0">
                {{ value.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import AgentServices from "@/apiServices/AgentServices";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import LmsServices from "@/apiServices/LmsServices";
import CounselorServices from "@/apiServices/CounselorServices";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    DateRangePicker,
  },
  data() {
    const startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const endDate = new Date();
    return {
      statistics: {
        totalEvents: {
          icon: "CalendarIcon",
          color: "light-primary",
          value: "...",
          subtitle: "Events",
        },

        leadCounts: {
          icon: "UserIcon",
          color: "light-dark",
          value: "...",
          subtitle: "Lead ",
        },
        counseledCounts: {
          icon: "UsersIcon",
          color: "light-info",
          value: "...",
          subtitle: "Counseled ",
        },
        applicationCounts: {
          icon: "UserCheckIcon",
          color: "light-info",
          value: "...",
          subtitle: "Applications",
        },
        depositStudents: {
          icon: "DollarSignIcon",
          color: "light-success",
          value: "...",
          subtitle: "Deposits",
        },
        visaStudents: {
          icon: "GlobeIcon",
          color: "light-info",
          value: "...",
          subtitle: "Visa",
        },
        activeStudents: {
          icon: "UserCheckIcon",
          color: "light-info",
          value: "...",
          subtitle: "Active",
        },
        joinedStudents: {
          icon: "UserIcon",
          color: "light-info",
          value: "...",
          subtitle: "Joined",
        },
      },
      quarter: null,
      dateRange: {
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
      },
      date: `${moment(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format("YYYY-MM-DD")} to ${moment(new Date()).format("YYYY-MM-DD")}`,

      opens: "left",
      locale: { firstDay: 1, format: " " },
      ranges: {
        Today: [new Date(), new Date()],
        "This Week": [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay())
          ),
          new Date(),
        ],
        "Last Week": [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 7)
          ),
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 1)
          ),
        ],
        "This Month": [
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date(),
        ],
        "This Year": [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31),
        ],
        "This Quarter": [
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3,
            1
          ),
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3 + 3,
            0
          ),
        ],
        Lifetime: [null, null],
      },
      autoApply: true,
      maxDate: new Date(),
      selectedFilterName: "This Month",
      filters: ["This Month", "This Quarter", "This Year", "Lifetime"],
      lead_count: 0,
      lmsApi: null,
    };
  },

  watch: {
    user_type: {
      immediate: true,
      handler(newVal) {},
    },
  },

  computed:{
user_id(){
  if (store.getters["user/getUserDetails"]) {
    return store.getters["user/getUserDetails"].user_id;
  }
},
user_type(){
  if (store.getters["user/getUserDetails"]) {
    return store.getters["user/getUserDetails"].user_type;
  }
}
  },
  methods: {
    
    async getAggregatedFranchiseStatistics() {
      try {
        const manager_id = this.user_id;
        const payload = { manager_id};
        const res = await CounselorServices.getAggregatedStats(payload)
        if (res.data.status) {
          const statistics = res.data.data
          this.statistics.totalEvents.value = statistics.total_event_count 
          this.statistics.applicationCounts.value = statistics.total_applications;
          this.statistics.depositStudents.value = statistics.total_deposit_students;
          this.statistics.visaStudents.value = statistics.total_visas;
        }
      } catch (error) {
        console.error("Error in getting franchise statistics:", error);
      }
    },
   
    onFilterChange() {
      this.getAggregatedFranchiseStatistics();
      this.getLMSAccess();
    },
    redirectTo(subtitle) {
      subtitle = subtitle.trim();
      switch (subtitle) {
        case "Events":
          this.$router.push({
            path: "/events",
            query: { action: "events" },
          });
          break;
        case "Lead":
          this.$router.push({
            path: "/my_leads",
            query: { subtitle: "lead" },
          });
          break;
        case "Counseled":
          this.$router.push({
            path: "/student_list",
            query: { conversion: "Y" },
          });
          break;
        case "Applications":
          this.$router.push({
            path: "/student_list",
            query: { application_status_id: 3 },
          });
          break;
        case "Deposits":
          this.$router.push({
            path: "/student_list",
            query: { application_status_id: 5 },
          });
          break;
        case "Visa":
          this.$router.push({
            path: "/student_list",
            query: { application_status_id: 8 },
          });
          break;
        case "Active":
          this.$router.push({
            path: "/student_list",
            query: { student_status: "active" },
          });
          break;
        case "Joined":
          this.$router.push({
            path: "/student_list",
            query: { student_status: "joined" },
          });
          break;
        default:
          return;
      }
      // if (subtitle === "Lead" || subtitle === "Counseled") {
      //   this.$router.push({
      //     path: "/my_leads",
      //     query: { subtitle: subtitle.toLowerCase() },
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/student_list",
      //     query: { subtitle: subtitle.toLowerCase() },
      //   });
      // }
    },
    async getMyLeads() {
      try {
        let res;
        if (this.date === "Lifetime") {
          res = await this.lmsApi.getMyLeads();
        } else {
          // console.log("Statssdate", this.date);
          res = await this.lmsApi.getMyLeads({
            registration_date: this.date,
          });
        }

        if (res.data.status) {
          this.statistics.leadCounts.value = res.data.data.total;
        }
      } catch (error) {
        console.error(`Error in getMyLeads `, error);
      }
    },
    async getLMSAccess() {
      try {
        const res = await CommonServices.getLMSAccessToken();
        this.accessToken = res.data.data;

        if (!this.accessToken) {
          this.accessTokenFailed = true;
          return;
        }

        this.lmsApi = LmsServices(this.accessToken);

        await this.getMyLeads();
        // await this.getMyCounseledLeads();
      } catch (error) {
        console.error(`Error in getLMSAccess `, error);
      }
    },
  },
  props: {
    franchise_id: {
      type: Number,
      default: null,
    },
    // user_type: {
    //   type: String,
    //   default: null,
    // },
  },
  beforeMount() {
    if (this.user_type && this.user_type === "counselor") {
      this.getAggregatedFranchiseStatistics();
    } else {
    }
    this.getLMSAccess();
  },
};
</script>
<style scoped>
.filter_text {
  font-size: small;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 5px;
  padding-bottom: 2px;
}
.filters {
  display: flex;
  gap: 10px;
}

.filters span {
  cursor: pointer;
  padding-bottom: 5px;
}

.filters span.active {
  border-bottom: 2px solid #00d09c;
}
.filter_text__ {
  font-size: smaller;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 5px;
  padding-bottom: 2px;
}
.vue-daterange-picker[data-v-1ebd09d2] {
  position: relative;
  display: inline-block;
  min-width: 20px !important;
}
.b_card_earning {
  position: relative;
}
.b_header_earning {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 18px;
  right: 22px;
}

.selected_filter {
  position: absolute;
  top: 22px;
  left: 120px;
}
</style>
