<template>
  <div>
    <b-card-body class="pt-0 mt-2">
      <div class="earnings">
        <div class="mb-2 mb-lg-0 ">
          <div class="inner_stats">
            <div>
              <div class="font-medium-1">Total Earnings</div>
              <h5 class="mb-1">₹{{ totalEarnings.toLocaleString("en-IN") }}</h5>
            </div>
            <div class="ml-3 ml-xl-0">
              <div class="font-small-2 package_text ">University Commission</div>
              <h5>₹{{ series[0].toLocaleString("en-IN") }}</h5>
            </div>
            <div class="ml-3 ml-xl-0">
              <div class="font-small-2 service_text cursor-pointer " @click="goToOtherIncome">Other Income</div>
              <h5>₹{{ series[1].toLocaleString("en-IN") }}</h5>
            </div>
          </div>

        </div>
        <div class="">
          <!-- chart -->
          <vue-apex-charts type="donut" v-if="isChartDataReady" :options="earningsChart.chartOptions"
            :series="earningsChart.series" />
        </div>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BCardBody,
  BCardHeader,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
    BCardBody,
    BCardHeader,
    DateRangePicker,
  },

  data() {
    return {
      franchise_statistics: {},
      serviceCommission: 0,
      packageCommission: 0,
      // totalEarnings: 0,

      earningsChart: {
        series: [],
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },

          legend: {
            show: true,
            position: "right",
            horizontalAlign: "center",
            fontSize: "12px",
            labels: {
              colors: ["#333"],
            },
            markers: {
              width: 12,
              height: 12,
              radius: 6,
            },
          },
          comparedResult: [2, -3],
          labels: ["Univ. Comm.", "Other Income"],
          stroke: { width: 0 },
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              // customScale: 0.8,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      return `₹${parseFloat(val).toLocaleString("en-IN")}`;
                    },
                  },
                  total: {
                    show: true,
                    label: " ",
                    offsetY: 15,
                    formatter: () =>
                      `₹${this.totalEarnings.toLocaleString("en-IN")}`,
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 2000,
              options: {
                chart: {
                  height: 300,
                },
              },
            },
            {
              breakpoint: 1700,
              options: {
                chart: {
                  height: 250,
                },
              },
            },
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 250,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 250,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 200,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 200,
                },
              },
            },
            {
              breakpoint: 768,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 80,
                },
              },
            },
          ],
        },
      },
    };
  },

  methods: {
    goToOtherIncome() {
      this.$emit("goToOtherIncome");

    }
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    totalEarnings: {
      type: Number,
      default: 0,
    },
    isChartDataReady: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    series: {
      immediate: true,
      handler(newSeries) {
        this.earningsChart.series = [...newSeries];
      },
    },
  },
  beforeMount() { },
};
</script>
<style scoped>
.package_text {
  color: #4382df;
  font-weight: 600;
}

.service_text {
  color: #34c759;
  font-weight: 600;
}

.filter_text {
  font-size: small;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 5px;
  padding-bottom: 2px;
}

.earnings {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.inner_stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
}

@media screen and (max-width: 1300px) {
  .earnings {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .inner_stats {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    height: 100%;
  }
}
</style>
