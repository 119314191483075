<template>
  <b-card
    style="min-height: 200px"
    title="Bank Accounts"
  >
  <div class="d-flex justify-content-end history_button">
    <b-button variant="primary" size="sm" @click="showHistory">
      Show History
    </b-button>
  </div>
    <div class="mt-2">
      <div class="d-flex flex-wrap">
        <div
          v-for="(bank, index) in banks"
          :key="index"
          class="px-3 d-flex w-50"
        >
          <b-card class="bank_account w-100" border-variant="primary">
            <template #header>
              <div class="d-flex justify-content-between w-100">
                <h4>{{ bank.user_fullname }}</h4>
                <div>
                  <b-button
                    variant="success"
                    size="sm"
                    v-if="bank.is_approved == 'Y'"
                  >
                    Approved
                  </b-button>
                  <b-button
                    variant="secondary"
                    size="sm"
                    @click="openModal(bank.ba_id, 'approve')"
                    >Approve
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    class="ml-2"
                    @click="openModal(bank.ba_id, 'disapprove')"
                    >Disapprove
                  </b-button>
                </div>
              </div>
            </template>

            <div class="bank_card_body">
              <b-row>
                  <b-col sm="6">
                    <b-card-text class="card_text"><b>Bank Name:</b></b-card-text>
                  </b-col>
                  <b-col sm="6">
                    <b-card-text class="card_text">{{ bank.ba_name }}</b-card-text>
                  </b-col>
                  <b-col sm="6">
                    <b-card-text class="card_text"><b>Bank Swift/Routing Number:</b></b-card-text>
                  </b-col>
                  <b-col sm="6">
                    <b-card-text class="card_text">{{ bank.ba_routing_number }}</b-card-text>
                  </b-col>
                  <b-col sm="6">
                    <b-card-text class="card_text"><b>Bank Address:</b></b-card-text>
                  </b-col>
                  <b-col sm="6">
                    <b-card-text class="card_text">{{ bank.ba_address }}</b-card-text>
                  </b-col>
                  <b-col sm="6">
                    <b-card-text class="card_text"><b>Bank Name:</b></b-card-text>
                  </b-col>
                  <b-col sm="6">
                    <b-card-text class="card_text">{{ bank.ba_name }}</b-card-text>
                  </b-col>
                  <b-col sm="6">
                    <b-card-text class="card_text"><b>Account Number:</b></b-card-text>
                  </b-col>
                  <b-col sm="6">
                    <b-card-text class="card_text">{{ bank.ba_number }}</b-card-text>
                  </b-col>
                </b-row>
            </div>
          </b-card>
        </div>

        <div v-if="!banks.length" class="text-center w-100 mt-2">
          <h4 >No Bank Accounts Available for Approval</h4>
        </div>
      </div>

      <!-- Modal  -->
      <div>
        <b-modal id="approve-bank-modal" title="Approve Bank" hide-footer>
          <p>
            Are you sure you want to {{ type }} the bank account for this
            franchise?
          </p>
          <div class="d-flex justify-content-end mt-1">
            <b-button variant="primary" @click="approve"> Ok </b-button>
          </div>
        </b-modal>
      </div>

      <!-- Remark Modal  -->
      <div>
        <b-modal
          id="remark-modal"
          title="Disapprove Bank"
          size="md"
          hide-footer
        >
          <b-form-textarea
            v-model="remark"
            placeholder="Enter reason here..."
            rows="3"
           
          ></b-form-textarea>
          <b-button variant="primary" class="mt-2" @click="approve">
            Ok
          </b-button>
        </b-modal>
      </div>


      <!-- History Modal  -->
       <div>
        <b-modal id="history-modal" title="Bank Account History" size="xl" hide-footer>
          <b-table
            bordered
            hover
            responsive
            :fields="history_fields"
            :items="history"
          >
            <template #cell(status)="data">
              <!-- {{ data }} -->
              <b-badge v-if="data.item.is_approved === 'Y'" variant="success">Approved</b-badge>
              <b-badge variant="danger" v-else-if="data.item. is_disapproved === 'Y'">Disapproved</b-badge>
              <b-badge variant="warning" v-else>Approval Pending</b-badge>
            </template>
          </b-table>
        </b-modal>
       </div>
    </div>
  </b-card>
</template>

<script>
import CounselorServices from "@/apiServices/CounselorServices";
import user from "@/store/user";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BFormTextarea,
  BRow,
  BTable,
} from "bootstrap-vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCardText,
    BCardBody,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormTextarea,
    BTable,
    BBadge
  },
  data() {
    return {
      ba_id: null,
      type: "",
      remark: "",
      history: [],
      history_fields: [
              { key: 'user_fullname', label: 'Franchise' },
              { key: 'status', label: 'Status', },
              { key: 'approved_by_fullname', label: 'Approved By' , formatter: (value) => value ? value : '-' },
              { key: 'approved_at', label: 'Approved On' , formatter: (value) => value ? value : '-' },
              { key: 'disapproved_by_fullname', label: 'Disapproved By' , formatter: (value) => value ? value : '-' },
              { key: 'disapproved_at', label: 'Disapproved On' , formatter: (value) => value ? value : '-' },
              { key: 'remarks', label: 'Remark' , formatter: (value) => value ? value : '-' },
            ]
    };
  },
  props: {
    banks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    user_type() {
      if (user.getters["user/getUserDetails"]?.user_type) {
        return user_type;
      } else {
        return null;
      }
    }
  },

  methods: {
    openModal(ba_id, type) {
      this.type = type;
      this.ba_id = ba_id;

      if (type == "disapprove") {
        this.$bvModal.show("remark-modal");
      } else {
        this.$bvModal.show("approve-bank-modal");
      }
    },

    approve() {
      this.approveBank();
    },

    async approveBank() {
      try {
        const res = await CounselorServices.approveBank({
          ba_id: this.ba_id,
          type: this.type,
          remark: this.remark,
        });

        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Bank account ${this.type}d successfully`,
              icon: "Check",
              variant: "success",
            },
          });

          this.$bvModal.hide("remark-modal");
          this.remark = "";
          this.type = "";

          this.$bvModal.hide("approve-bank-modal");

          setTimeout(() => {
            this.$emit("refreshData");
          }, 2000);
          this.BankApprovalHistory();

        }
      } catch (error) {
        console.error("Error in approveBank ", error);
      }
    },

    async BankApprovalHistory(){
      try {
        const res = await CounselorServices.getBankApprovalHistory(); 
        if (res.data.status) {
          this.history = res.data.data;
        } 
      } catch (error) {
        console.error("Error in BankApprovalHistory ", error);
      }
    },

    showHistory(){
this.$bvModal.show("history-modal");
    }
  },

  beforeMount() {
    this.BankApprovalHistory();

  },
};
</script>
<style>
.bank_card_body {
  position: relative;
}
.edit {
  position: absolute;
  top: 10px;
  right: 20px;
}
.bank_account {
  box-shadow: 10px 5px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  border-radius: 10px;
}
.add_account_button {
  position: absolute;
  top: 0;
  right: 20px;
}
.history_button{
  position: absolute;
  top: 18px;
  right: 20px;
}
.card_text{
  font-size: clamp(1rem, 1.5rem, 1.2rem);
  margin-top: 10px;
}
</style>
