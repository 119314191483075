<template>
  <div class="expense_card">  
    <!-- <b-card title="Monthly Expenses" class="expense_card"> -->
      <b-modal
        id="add-expense-modal"
        size="md"
        title="Add Expense"
        hide-footer
        :no-close-on-backdrop="true"
        centered
      >
        <b-form @submit.prevent="submitExpenseForm">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label-class="h5 font-weight-bold"
                label="Month"
                label-for="select-month"
              >
                <v-select
                  id="select-month"
                  v-model="newExpense.me_month"
                  :options="all_months"
                 
                  placeholder="Select Month"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label-class="h5 font-weight-bold"
                label="Year"
                label-for="select-year"
              >
                <v-select
                  id="select-year"
                  v-model="newExpense.me_year"
                  :options="[
                    new Date().getFullYear(),
                    new Date().getFullYear() - 1,
                  ]"
                  placeholder="Select Year"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label-class="h5 font-weight-bold"
                label="Type of Expense"
                label-for="select-type"
              >
                <v-select
                  id="select-type"
                  v-model="newExpense.me_type"
                  :reduce="(type) => type"
                  :options="expenseTypes"
                  placeholder="Select Type"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label-class="h5 font-weight-bold"
                label="Amount"
                label-for="input-amount"
              >
                <b-form-input
                  id="input-amount"
                  v-model="newExpense.me_amount"
                  type="number"
                  placeholder="Enter Amount"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-class="h5 font-weight-bold"
                label="Remark"
                label-for="input-remark"
              >
                <b-form-textarea
                  id="input-remark"
                  v-model="newExpense.me_remark"
                  placeholder="Enter Remark"
                  rows="2"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end mt-2 mb-0">
            <b-button variant="primary" @click="submitExpenseForm"
              >Add Expense</b-button
            >
          </div>
        </b-form>
      </b-modal>
      <div class="add_expense_btn"  v-if="user_type === 'franchise'">
        <b-button
          variant="outline-primary"
          size="sm"
          class="mt-2"
          @click="$bvModal.show('add-expense-modal')"
         
        >
          Add Expense
        </b-button>
      </div>

      <b-table
        show-empty
        responsive
        :items="expenses"
        :fields="expense_table_fields"
        bordered
        sticky-header
      >
        <template #cell(showdetails)="row">
          <!-- {{ row }} -->
          <b-form-checkbox
            v-model="row.detailsShowing"
            :disabled="activeRow !== null && activeRow !== row.index"
            @change="
              (e) => {
                row.toggleDetails(e);
                getMonthlyExpenses(row.item.me_month, row.item.me_year);
                if (e) {
                    activeRow = row.index;
                  } else {
                    activeRow = null;
                  }
              }
            "
          />
        </template>
        <template #cell(sr_no)="{ index }">
          {{ index + 1 }}
        </template>

        <template #cell(month)="data">
          <!-- {{ data }} -->
          {{ data.item.me_month }} {{ data.item.me_year }}
        </template>

        <template #cell(amount)="data">
          {{ data.item.total_amount }}
        </template>
        <template #row-details="row">
          <b-table
            show-empty
            responsive
            :items="monthlyExpenses"
            :fields="monthly_expense_table_fields"
            bordered
          >
            <template #cell(sr_no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(type)="data">
              {{ data.item.me_type }}
            </template>
            <template #cell(amount)="data">
              {{ data.item.me_amount }}
            </template>
            <template #cell(remark)="data">
              {{ data.item.me_remark }}
            </template>
            <template #cell(edit)="data">
              <feather-icon
                icon="EditIcon"
                size="20"
                class="cursor-pointer"
                @click="editMonthlyExpenses(data.item.me_id)"
              />
            </template>
          </b-table>
        </template>
      </b-table>
    <!-- </b-card> -->
  </div>
</template>
<script>
import AgentServices from "@/apiServices/AgentServices";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import CounselorServices from "@/apiServices/CounselorServices";
import user from "@/store/user";

export default {
  components: {
    BTable,
    BCard,
    BFormCheckbox,
    BButton,
    BFormGroup,
    vSelect,
    BFormInput,
    BFormTextarea,
    BForm,
    BRow,
    BCol
  },
  data() {
    return {
      expense_table_fields: [
        { key: "showdetails", label: "show", sortable: false },

        // { key: "sr_no", label: "Sr. No" },
        { key: "month", label: "Month" },
        { key: "amount", label: "Amount" },
      ],
      monthly_expense_table_fields: [
        // { key: "sr_no", label: "Sr. No" },
        { key: "type", label: "Type" },
        { key: "amount", label: "Amount" },
        { key: "remark", label: "Remark" },
        { key: "edit", label: "Edit" },
      ],
      expenses: [],
      monthlyExpenses: [],
      newExpense: {
        me_month: moment().format("MMMM"),
        me_year: moment().format("YYYY"),
        me_type: null,
        me_amount: null,
        me_remark: null,
      },
      all_months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      expenseTypes: ["Rent", "Salary", "Marketing","Incentives", "Other"],
      activeRow: null,
    };
  },
  props: {
    month: {
      type: String,
      default: "",
    },
    year: {
      type: Number,
      default: "",
    },
    user_type: {
      type: String,
      default: "",
    },
    franchise_id: {
      type: Number,
      
    }
  },
  watch: {
    month() {
      this.getAllMonthlyExpenses();
    },
    year() {
      this.getAllMonthlyExpenses();
    },
    user_type() {
      this.getAllMonthlyExpenses();
    }
  },
  methods: {
    async getAllMonthlyExpenses() {
      try {
        const payload = {
          month: this.month,
          year: this.year,
          user_id: this.franchise_id
        };

        let res;

        if(this.user_type === "franchise") {
           res = await AgentServices.getAllMonthlyExpenses(payload);
        }
        if(this.user_type === "counselor") {
           res = await CounselorServices.getAllMonthlyExpenses(payload);
        }

        if (res.data.status) {
          this.expenses = res.data.data;
        }
      } catch (error) {
        console.error("Error in getAllMonthlyExpenses ", error);
      }
    },

    async getMonthlyExpenses(month, year) {
      try {
        const payload = {
          month,
          year,
          user_id: this.franchise_id
        };
        let res;
        if(this.user_type === "franchise") {
          res = await AgentServices.getMonthlyExpenses(payload);
        }
        if(this.user_type === "counselor") {
          res = await CounselorServices.getMonthlyExpenses(payload);
        }

        if (res.data.status) {
          this.monthlyExpenses = res.data.data;
        }
      } catch (error) {
        console.error("Error in getMonthlyExpenses ", error);
      }
    },
    async submitExpenseForm() {
      try {
        console.log(this.newExpense);
        const res = await AgentServices.addMonthlyExpenses(this.newExpense);
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Expense Added Successfully",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.getMonthlyExpenses(this.newExpense.me_month, this.newExpense.me_year);
          this.getAllMonthlyExpenses();
          this.resetExpenseForm();
          this.$bvModal.hide("add-expense-modal");
          this.$emit("refreshBarChart");
        }
      } catch (error) {
        console.error("Error in submitExpenseForm ", error);
      }
    },
    async editMonthlyExpenses(expense_id) {
      try {
        console.log(expense_id);
        this.$bvModal.show("add-expense-modal");

        const res = await AgentServices.getMonthlyExpensesById(expense_id);
        if (res.data.status) {
          console.log("Expense Updated",res);
          this.newExpense = res.data.data;
          console.log(this.newExpense);
        }
      } catch (error) {
        console.error("Error in editMonthlyExpenses ", error);
      }
    },
    resetExpenseForm() {
      this.newExpense = {
        me_month: moment().format("MMMM"),
        me_year: moment().format("YYYY"),
        me_type: null,
        me_amount: null,
        me_remark: null,
      };
    },
  },
  beforeMount() {
    this.getAllMonthlyExpenses();
    // this.getMonthlyExpenses();
  },
};
</script>

<style>
.expense_card {
  position: relative;
}
.add_expense_btn {
  position: absolute;
  top: -55px;
  right: 10px;
}
</style>
